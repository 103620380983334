import { Fragment, PropsWithChildren, useContext } from "react";
import { Controller, useFieldArray } from "react-hook-form";
import validator from "validator";
import landRegistrationInstructionsPdf from "../../assets/anleitung-katasterdaten.pdf";
import reaInstructionsPdf from "../../assets/anleitung-vwv-nummer.pdf";
import { useMember } from "../../context/memberContext";
import NotificationDispatch, {
  showErrorNotification,
  showSuccessNotification,
} from "../../context/notificationContext";
import useToggle from "../../hooks/useToggle";
import {
  SP,
  putDac7Member,
  useCountries,
  useDac7Member,
} from "../../http/serviceportalApi";
import i18n from "../../i18n";
import { getRemoteDataStatus, mergeStatuses } from "../../utils";
import SvgAdd from "../icons/Add";
import SvgDelete from "../icons/Delete";
import ButtonGroup from "../ui/ButtonGroup";
import ErrorText from "../ui/ErrorText";
import Fieldset from "../ui/Fieldset";
import Form from "../ui/Form";
import FormField from "../ui/FormField";
import Icon from "../ui/Icon";
import Input from "../ui/Input";
import Page from "../ui/Page";
import Panel from "../ui/Panel";
import PdfPreview from "../ui/PdfPreview";
import Select from "../ui/Select";
import CountrySelect from "./CountrySelect";
import styles from "./Dac7.module.css";

const emptyLandRegistration: SP.Dac7.PutMemberLandRegistration = {
  code: "",
  sheet: "",
  parcel: "",
  subaltern: "",
};

const Dac7 = () => {
  const dispatch = useContext(NotificationDispatch);
  const { member } = useMember();

  const {
    data,
    isValidating: isMemberValidating,
    error: memberError,
    mutate,
  } = useDac7Member(member?.id);
  const memberStatus = getRemoteDataStatus({
    isValidating: isMemberValidating,
    error: memberError,
  });
  const isCompany = data?.type === "company";
  const isSoleProprietorship = data?.type === "soleProprietorship";
  const isPrivatePerson = data?.type === "privatePerson";

  const {
    data: countryListData,
    isValidating: isCountryListValidating,
    error: countryListError,
  } = useCountries();
  const countries = countryListData ?? [];
  const sortedCountries = {
    byAlpha2Code: (countryListData ?? []).sort(byAlpha2Code),
    byName: (countryListData ?? []).sort(byName),
  };
  const countryListStatus = getRemoteDataStatus({
    isValidating: isCountryListValidating,
    error: countryListError,
  });

  const status = mergeStatuses([memberStatus, countryListStatus]);

  const reaInstructionsModal = useToggle();
  const landRegistrationsInstructionsModal = useToggle();

  if (!member) return null;

  return (
    <Page
      title="DAC7 Meldepflicht"
      description={
        <>
          Aufgrund des legislativen Dekrets Nr. 32/2023, welches die
          EU-Richtlinie „DAC7“ in Italien umsetzt, sind digitale
          Plattformbetreiber dazu verpflichtet, europäischen Steuerbehörden
          Daten und Transaktionen der registrierten Verkäufer offenzulegen.
          <br />
          Bitte ergänzen Sie die fehlenden Daten bis zum 28.12.2023, um eine
          Deaktivierung der Online-Buchbarkeit zu vermeiden.
        </>
      }
      backLinkText={`Zurück zu ${i18n.departments.webMarketing.title}`}
      isLoading={status === "validating"}
    >
      {status === "success" && (
        <Form<SP.Dac7.PutMemberBody>
          defaultValues={{
            fiscalCodeIssuedBy: data?.fiscalCodeIssuedBy ?? "IT",
            fiscalCode: data?.fiscalCode,
            birthDate: data?.birthDate
              ? formatYearMonthDate(new Date(data.birthDate))
              : undefined,
            pec: data?.pec,
            accountOwner: data?.accountOwner,
            iban: data?.iban,
            vat: data?.vat,
            rea: data?.rea,
            permanentEstablishments: data?.permanentEstablishments ?? [],
            address: data?.address,
            companies:
              data?.companies?.map(({ id, address, landRegistrations }) => ({
                id,
                address,
                landRegistrations:
                  landRegistrations && landRegistrations.length > 0
                    ? landRegistrations
                    : [emptyLandRegistration],
              })) ?? [],
          }}
          onSubmit={() => (formData) => {
            const body: SP.Dac7.PutMemberBody = {
              fiscalCodeIssuedBy: formData.fiscalCodeIssuedBy,
              fiscalCode: formData.fiscalCode.toUpperCase(),
              birthDate:
                isSoleProprietorship || isPrivatePerson
                  ? formData.birthDate
                  : undefined,
              pec: formData.pec,
              accountOwner: formData.accountOwner,
              iban: formData.iban.toUpperCase(),
              vat:
                isCompany || isSoleProprietorship
                  ? formData.vat?.toUpperCase()
                  : undefined,
              rea:
                isCompany || isSoleProprietorship
                  ? formData.rea?.toUpperCase()
                  : undefined,
              permanentEstablishments: isCompany
                ? formData.permanentEstablishments
                : [],
              address: formData.address,
              companies: formData.companies.map((c) => ({
                ...c,
                landRegistrations: c.landRegistrations.reduce(
                  (state: SP.Dac7.PutMemberLandRegistration[], value) => {
                    if (
                      !value.code &&
                      !value.sheet &&
                      !value.parcel &&
                      !value.subaltern
                    )
                      return state;
                    return [
                      ...state,
                      {
                        ...value,
                        code: value.code?.toUpperCase(),
                      },
                    ];
                  },
                  []
                ),
              })),
            };

            putDac7Member(member.id, body)
              .then((updated) => {
                mutate(updated, { revalidate: false });
                dispatch(showSuccessNotification());
              })
              .catch((err) => dispatch(showErrorNotification(err)));
          }}
        >
          {({ control, watch, register, formState: { errors } }) => {
            const fiscalCodeIssuedBy = watch("fiscalCodeIssuedBy");

            return (
              <>
                <Section title="Angaben zum Verkäufer">
                  <Panel>
                    <FormField
                      label="Steuerliche Klassifikation"
                      direction="column"
                      helpText="Sollte diese Information nicht aktuell sein, so bitten wir Sie, uns zu kontaktieren."
                    >
                      <ButtonGroup>
                        <ButtonGroup.Button
                          disabled={true}
                          isActive={isCompany}
                        >
                          Unternehmen
                        </ButtonGroup.Button>
                        <ButtonGroup.Button
                          disabled={true}
                          isActive={isSoleProprietorship}
                        >
                          Einzelunternehmen
                        </ButtonGroup.Button>
                        <ButtonGroup.Button
                          disabled={true}
                          isActive={isPrivatePerson}
                        >
                          Privatperson
                        </ButtonGroup.Button>
                      </ButtonGroup>
                    </FormField>

                    <Separator />

                    <div className={styles.fieldGrid}>
                      {isCompany && (
                        <FormField
                          label={"Juristischer Firmenname"}
                          direction="column"
                          helpText="Dies ist die rechtsgültige Bezeichnung Ihres Unternehmens. Sollte diese nicht aktuell sein, so bitten wir Sie, uns zu kontaktieren."
                        >
                          {({ labelId }) => (
                            <Input
                              id={labelId}
                              disabled={true}
                              defaultValue={data?.companyName}
                            />
                          )}
                        </FormField>
                      )}
                      {(isSoleProprietorship || isPrivatePerson) && (
                        <>
                          <FormField
                            label="Vorname"
                            direction="column"
                            helpText="Dies ist Ihr rechtsgültiger Vorname. Sollte dieser nicht korrekt sein, so bitten wir Sie, uns zu kontaktieren."
                          >
                            {({ labelId }) => (
                              <Input
                                id={labelId}
                                disabled={true}
                                defaultValue={data?.firstName}
                              />
                            )}
                          </FormField>
                          <FormField
                            label="Nachname"
                            direction="column"
                            helpText="Dies ist Ihr rechtsgültiger Nachname. Sollte dieser nicht korrekt sein, so bitten wir Sie, uns zu kontaktieren."
                          >
                            {({ labelId }) => (
                              <Input
                                id={labelId}
                                disabled={true}
                                defaultValue={data?.lastName}
                              />
                            )}
                          </FormField>
                        </>
                      )}
                      {(isSoleProprietorship || isPrivatePerson) && (
                        <FormField
                          label="Geburtsdatum"
                          direction="column"
                          error={errors.birthDate}
                        >
                          {({ labelId, isOptional, isInvalid }) => (
                            <Input
                              id={labelId}
                              isInvalid={isInvalid}
                              type="date"
                              {...register("birthDate", {
                                required: !isOptional,
                              })}
                            />
                          )}
                        </FormField>
                      )}
                      <FormField
                        label="PEC-Adresse"
                        direction="column"
                        error={errors.pec}
                      >
                        {({ labelId, isOptional, isInvalid }) => (
                          <Input
                            id={labelId}
                            isInvalid={isInvalid}
                            inputMode="email"
                            autoComplete="email"
                            {...register("pec", {
                              required: !isOptional,
                              validate: (v) => validator.isEmail(v),
                            })}
                          />
                        )}
                      </FormField>
                      <FormField
                        label="Steuernummer"
                        direction="column"
                        error={errors.fiscalCode}
                        helpText="Geben Sie Ihre Steuernummer und das Land, von dem sie ausgestellt wurde, an."
                      >
                        {({ labelId, isOptional, isInvalid }) => (
                          <div className={styles.fiscalCode}>
                            <Select {...register("fiscalCodeIssuedBy")}>
                              {sortedCountries.byAlpha2Code.map((c) => (
                                <option key={c.alpha2Code} value={c.alpha2Code}>
                                  {c.alpha2Code}
                                </option>
                              ))}
                            </Select>
                            <Input
                              id={labelId}
                              className={styles.uppercase}
                              isInvalid={isInvalid}
                              {...register("fiscalCode", {
                                required: !isOptional,
                                validate: (v) => {
                                  try {
                                    const locale = countries.find(
                                      (c) => c.alpha2Code === fiscalCodeIssuedBy
                                    )?.locale;
                                    return (
                                      !locale ||
                                      validator.isTaxID(v, locale) ||
                                      validator.isVAT(
                                        fiscalCodeIssuedBy + v,
                                        fiscalCodeIssuedBy
                                      )
                                    );
                                  } catch {
                                    // Skip validation if the selected country/locale is not supported
                                    return true;
                                  }
                                },
                              })}
                            />
                          </div>
                        )}
                      </FormField>
                      {(isCompany || isSoleProprietorship) && (
                        <>
                          <FormField
                            label="MwSt.-Nummer"
                            direction="column"
                            error={errors.vat}
                            helpText="Geben Sie Ihre MwSt.-Nummer inklusive zweistelligem Länderkürzel an."
                          >
                            {({ labelId, isOptional, isInvalid }) => (
                              <Input
                                id={labelId}
                                className={styles.uppercase}
                                isInvalid={isInvalid}
                                {...register("vat", {
                                  required: !isOptional,
                                  pattern: /^[A-Z]{2}/i,
                                  // Only italian VAT codes are allowed
                                  validate: (v) => {
                                    try {
                                      return (
                                        v &&
                                        (validator.isTaxID(v, "it-IT") ||
                                          validator.isVAT(v, "IT"))
                                      );
                                    } catch {
                                      // Skip validation if the selected country/locale is not supported
                                      return true;
                                    }
                                  },
                                })}
                              />
                            )}
                          </FormField>
                          <FormField
                            label="VWV Nummer (REA Nummer)"
                            direction="column"
                            error={errors.rea}
                            helpText={
                              <>
                                Die VWV Nummer finden Sie auf der ersten Seite
                                des Handelskammerauszuges.{" "}
                                <a
                                  href={reaInstructionsPdf}
                                  target="_blank"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    reaInstructionsModal.open();
                                  }}
                                >
                                  Hier im Beispiel
                                </a>{" "}
                                ersichtlich anhand des roten Pfeils.
                                <PdfPreview
                                  isOpen={reaInstructionsModal.isOpen}
                                  onClose={reaInstructionsModal.close}
                                  title="Anleitung VWV Nummer"
                                  src={reaInstructionsPdf}
                                />
                              </>
                            }
                          >
                            {({ labelId, isOptional, isInvalid }) => (
                              <Input
                                id={labelId}
                                className={styles.uppercase}
                                isInvalid={isInvalid}
                                {...register("rea", {
                                  required: !isOptional,
                                  pattern: /(^[a-zA-Z]{2}-[0-9]+$)/,
                                })}
                              />
                            )}
                          </FormField>
                        </>
                      )}
                    </div>

                    <Separator />

                    <Fieldset title="Adresse">
                      <div className={styles.fieldGrid}>
                        <FormField
                          label="Straße"
                          direction="column"
                          error={errors.address?.street}
                        >
                          {({ labelId, isInvalid }) => (
                            <Input
                              id={labelId}
                              isInvalid={isInvalid}
                              {...register("address.street")}
                            />
                          )}
                        </FormField>
                        <FormField
                          label="Nummer"
                          direction="column"
                          error={errors.address?.buildingIdentifier}
                        >
                          {({ labelId, isInvalid }) => (
                            <Input
                              id={labelId}
                              isInvalid={isInvalid}
                              {...register("address.buildingIdentifier")}
                            />
                          )}
                        </FormField>
                        <FormField
                          label="Postleitzahl"
                          direction="column"
                          error={errors.address?.zip}
                        >
                          {({ labelId, isOptional, isInvalid }) => (
                            <Input
                              id={labelId}
                              isInvalid={isInvalid}
                              {...register("address.zip", {
                                required: !isOptional,
                              })}
                            />
                          )}
                        </FormField>
                        <FormField
                          label="Gemeinde"
                          direction="column"
                          error={errors.address?.city}
                        >
                          {({ labelId, isOptional, isInvalid }) => (
                            <Input
                              id={labelId}
                              isInvalid={isInvalid}
                              {...register("address.city", {
                                required: !isOptional,
                              })}
                            />
                          )}
                        </FormField>
                        <FormField
                          label="Provinz"
                          direction="column"
                          error={errors.address?.province}
                        >
                          {({ labelId, isOptional, isInvalid }) => (
                            <Input
                              id={labelId}
                              isInvalid={isInvalid}
                              {...register("address.province", {
                                required: !isOptional,
                              })}
                            />
                          )}
                        </FormField>
                        <FormField
                          label="Land"
                          direction="column"
                          error={errors.address?.country}
                        >
                          {({ labelId, isOptional, isInvalid }) => (
                            <Select
                              id={labelId}
                              className={styles.addressCountrySelect}
                              isInvalid={isInvalid}
                              {...register("address.country", {
                                required: !isOptional,
                              })}
                            >
                              {sortedCountries.byName.map((c) => (
                                <option key={c.alpha2Code} value={c.alpha2Code}>
                                  {c.name}
                                </option>
                              ))}
                            </Select>
                          )}
                        </FormField>
                      </div>
                    </Fieldset>

                    <Separator />

                    <Fieldset title="Bankdaten">
                      <div className={styles.fieldGrid}>
                        <FormField
                          label="Kontoinhaber"
                          direction="column"
                          error={errors.accountOwner}
                        >
                          {({ labelId, isOptional, isInvalid }) => (
                            <Input
                              id={labelId}
                              isInvalid={isInvalid}
                              {...register("accountOwner", {
                                required: !isOptional,
                              })}
                            />
                          )}
                        </FormField>
                        <FormField
                          label="IBAN"
                          direction="column"
                          error={errors.iban}
                        >
                          {({ labelId, isOptional, isInvalid }) => (
                            <Input
                              id={labelId}
                              className={styles.uppercase}
                              isInvalid={isInvalid}
                              {...register("iban", {
                                required: !isOptional,
                                validate: (v) => validator.isIBAN(v),
                              })}
                            />
                          )}
                        </FormField>
                      </div>
                    </Fieldset>

                    {isCompany && (
                      <>
                        <Separator />

                        <Fieldset
                          title="Niederlassungen"
                          description="Wählen Sie jene Länder aus, in denen Sie Betriebsstätten (innerhalb der Europäischen Union) vermieten."
                        >
                          <Controller
                            name="permanentEstablishments"
                            control={control}
                            rules={{
                              validate: (v) =>
                                v.length > 0 ||
                                "Wählen Sie mindestens ein Land aus.",
                            }}
                            render={({ field, fieldState: { error } }) => (
                              <CountrySelect
                                ref={field.ref}
                                countries={countries.filter(
                                  (c) => c.isEuMember
                                )}
                                selectedItems={field.value}
                                error={error}
                                onChange={field.onChange}
                              />
                            )}
                          />
                        </Fieldset>
                      </>
                    )}
                  </Panel>
                </Section>

                <Section
                  title="Angaben zu den Betrieben"
                  description="Geben Sie für jede Liegenschaftseinheit, die über das Portal „Booking Südtirol“ vermietet wird, jeweils folgende Daten an."
                >
                  {data?.companies?.map((c, i) => {
                    const { fields, append, remove } = useFieldArray({
                      name: `companies.${i}.landRegistrations`,
                      control,
                      keyName: "fieldId",
                    });
                    const companyErrors = errors.companies?.[i];

                    return (
                      <div key={c.id} className={styles.company}>
                        <h3 className={styles.companyName}>{c.name}</h3>
                        <Panel>
                          <Fieldset title="Adresse">
                            <div className={styles.fieldGrid}>
                              <FormField
                                label="Straße"
                                direction="column"
                                error={companyErrors?.address?.street}
                              >
                                {({ labelId, isInvalid }) => (
                                  <Input
                                    id={labelId}
                                    isInvalid={isInvalid}
                                    {...register(
                                      `companies.${i}.address.street`
                                    )}
                                  />
                                )}
                              </FormField>
                              <FormField
                                label="Nummer"
                                direction="column"
                                error={
                                  companyErrors?.address?.buildingIdentifier
                                }
                              >
                                {({ labelId, isInvalid }) => (
                                  <Input
                                    id={labelId}
                                    isInvalid={isInvalid}
                                    {...register(
                                      `companies.${i}.address.buildingIdentifier`
                                    )}
                                  />
                                )}
                              </FormField>
                              <FormField
                                label="Postleitzahl"
                                direction="column"
                                error={companyErrors?.address?.zip}
                              >
                                {({ labelId, isOptional, isInvalid }) => (
                                  <Input
                                    id={labelId}
                                    isInvalid={isInvalid}
                                    {...register(`companies.${i}.address.zip`, {
                                      required: !isOptional,
                                    })}
                                  />
                                )}
                              </FormField>
                              <FormField
                                label="Gemeinde"
                                direction="column"
                                error={companyErrors?.address?.city}
                              >
                                {({ labelId, isOptional, isInvalid }) => (
                                  <Input
                                    id={labelId}
                                    isInvalid={isInvalid}
                                    {...register(
                                      `companies.${i}.address.city`,
                                      {
                                        required: !isOptional,
                                      }
                                    )}
                                  />
                                )}
                              </FormField>
                              <FormField
                                label="Provinz"
                                direction="column"
                                error={companyErrors?.address?.province}
                              >
                                {({ labelId, isOptional, isInvalid }) => (
                                  <Input
                                    id={labelId}
                                    isInvalid={isInvalid}
                                    {...register(
                                      `companies.${i}.address.province`,
                                      {
                                        required: !isOptional,
                                      }
                                    )}
                                  />
                                )}
                              </FormField>
                              <FormField
                                label="Land"
                                direction="column"
                                error={companyErrors?.address?.country}
                              >
                                {({ labelId, isOptional, isInvalid }) => (
                                  <Select
                                    id={labelId}
                                    className={styles.addressCountrySelect}
                                    isInvalid={isInvalid}
                                    {...register(
                                      `companies.${i}.address.country`,
                                      {
                                        required: !isOptional,
                                      }
                                    )}
                                  >
                                    {sortedCountries.byName.map((c) => (
                                      <option
                                        key={c.alpha2Code}
                                        value={c.alpha2Code}
                                      >
                                        {c.name}
                                      </option>
                                    ))}
                                  </Select>
                                )}
                              </FormField>
                            </div>
                          </Fieldset>

                          <Separator />

                          <Fieldset
                            title="Katasterdaten"
                            description={
                              <>
                                <a
                                  href={landRegistrationInstructionsPdf}
                                  target="_blank"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    landRegistrationsInstructionsModal.open();
                                  }}
                                >
                                  In diesem Dokument
                                </a>{" "}
                                finden Sie die Anleitung, wie die abgefragten
                                Daten aus dem Katasterauszug der jeweiligen
                                Liegenschaftseinheit ausgelesen werden können.
                                <PdfPreview
                                  isOpen={
                                    landRegistrationsInstructionsModal.isOpen
                                  }
                                  onClose={
                                    landRegistrationsInstructionsModal.close
                                  }
                                  title="Angabe der Katasterdaten für die Meldung DAC7"
                                  src={landRegistrationInstructionsPdf}
                                />
                              </>
                            }
                          >
                            {fields.length > 0 && (
                              <div
                                className={styles.landRegistrationsGridWrapper}
                              >
                                <div className={styles.landRegistrationsGrid}>
                                  <LandRegistrationHeader
                                    title="Kodex der Gemeinde"
                                    subtitle="Codice catastale del comune"
                                  />
                                  <LandRegistrationHeader
                                    title="Blatt"
                                    subtitle="Foglio"
                                  />
                                  <LandRegistrationHeader
                                    title="Parzelle"
                                    subtitle="Particella"
                                  />
                                  <LandRegistrationHeader
                                    title="Baueinheit"
                                    subtitle="Sub. oder subalterno"
                                  />
                                  <div></div>

                                  {fields.map((lr, j) => {
                                    const landRegistrationErrors =
                                      companyErrors?.landRegistrations?.[j];

                                    return (
                                      <Fragment key={lr.fieldId}>
                                        <FormField
                                          isOptional={true}
                                          error={landRegistrationErrors?.code}
                                        >
                                          {({ isOptional, isInvalid }) => (
                                            <Input
                                              className={styles.uppercase}
                                              isInvalid={isInvalid}
                                              {...register(
                                                `companies.${i}.landRegistrations.${j}.code`,
                                                {
                                                  required: !isOptional,
                                                  pattern:
                                                    /^[A-Z]{1}[0-9]{3}$/i,
                                                }
                                              )}
                                            />
                                          )}
                                        </FormField>
                                        <FormField
                                          isOptional={true}
                                          error={landRegistrationErrors?.sheet}
                                        >
                                          {({ isOptional, isInvalid }) => (
                                            <Input
                                              isInvalid={isInvalid}
                                              {...register(
                                                `companies.${i}.landRegistrations.${j}.sheet`,
                                                {
                                                  required: !isOptional,
                                                  pattern: /^[0-9]+(-[0-9]+)?$/,
                                                }
                                              )}
                                            />
                                          )}
                                        </FormField>
                                        <FormField
                                          isOptional={true}
                                          error={landRegistrationErrors?.parcel}
                                        >
                                          {({ isOptional, isInvalid }) => (
                                            <Input
                                              isInvalid={isInvalid}
                                              {...register(
                                                `companies.${i}.landRegistrations.${j}.parcel`,
                                                { required: !isOptional }
                                              )}
                                            />
                                          )}
                                        </FormField>
                                        <FormField
                                          isOptional={true}
                                          error={
                                            landRegistrationErrors?.subaltern
                                          }
                                        >
                                          {({ isOptional, isInvalid }) => (
                                            <Input
                                              isInvalid={isInvalid}
                                              {...register(
                                                `companies.${i}.landRegistrations.${j}.subaltern`,
                                                {
                                                  required: !isOptional,
                                                  pattern: /^[0-9]+$/,
                                                }
                                              )}
                                            />
                                          )}
                                        </FormField>
                                        <button
                                          className={styles.removeEntryButton}
                                          type="button"
                                          onClick={() => remove(j)}
                                        >
                                          <Icon
                                            className={styles.deleteIcon}
                                            glyph={SvgDelete}
                                          />
                                        </button>
                                      </Fragment>
                                    );
                                  })}
                                </div>
                              </div>
                            )}
                            <button
                              className={styles.addEntryButton}
                              type="button"
                              onClick={() => append(emptyLandRegistration)}
                            >
                              <Icon className={styles.addIcon} glyph={SvgAdd} />
                              Eintrag hinzufügen
                            </button>
                          </Fieldset>
                        </Panel>
                      </div>
                    );
                  })}
                </Section>
              </>
            );
          }}
        </Form>
      )}

      {status === "failure" && (
        <div className={styles.status}>
          {memberError?.status === 404 ? (
            <>
              Die Konfiguration des gewählten Mitglieds wurde noch nicht
              vollständig abgeschlossen.
              <br />
              Bitte wenden Sie sich an die HGV-Abteilung Web Marketing.
            </>
          ) : (
            <ErrorText text="Fehler beim Laden der Mitgliedsdaten." />
          )}
        </div>
      )}
    </Page>
  );
};

type SectionProps = PropsWithChildren<{
  title: string;
  description?: string;
}>;

const Section = ({ title, description, children }: SectionProps) => (
  <section className={styles.section}>
    <h2>{title}</h2>
    {description && <p>{description}</p>}
    {children}
  </section>
);

const Separator = () => <hr className={styles.separator} />;

interface LandRegistrationHeaderProps {
  title: string;
  subtitle: string;
}

const LandRegistrationHeader = ({
  title,
  subtitle,
}: LandRegistrationHeaderProps) => (
  <div>
    <div className={styles.title}>{title}</div>
    <div className={styles.subtitle}>{subtitle}</div>
  </div>
);

const formatYearMonthDate = (date: Date) => {
  const year = String(date.getFullYear());
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

const byAlpha2Code = (a: SP.Country, b: SP.Country) =>
  a.alpha2Code.localeCompare(b.alpha2Code);

const byName = (a: SP.Country, b: SP.Country) => a.name.localeCompare(b.name);

export default Dac7;
