import { PropsWithChildren, createContext, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { getMetropolisUrl, getWebdeskUrl } from "../http/serviceportalApi";
import { App, Department, Permission } from "../types";
import { flattenPermissions, includesAny } from "../utils";
import { useMember } from "./memberContext";
import NotificationDispatch, {
  showErrorNotification,
} from "./notificationContext";

type AppsContextType = {
  apps: App[];
  recentlyUsedApps: App[];
  isValidating: boolean;
};

const AppsContext = createContext<AppsContextType>(undefined!);

const LOCAL_STORAGE_KEY_APPS = "recently-used-apps";

const openInNewTab = (url: string) => {
  window.open(url, "_blank");
};

const getRecentlyUsedAppIds = (): string[] => {
  const ids = localStorage.getItem(LOCAL_STORAGE_KEY_APPS);
  return ids ? (JSON.parse(ids) as string[]) : [];
};

const addRecentlyUsedApp = (app: App) => {
  const appIds = getRecentlyUsedAppIds();
  localStorage.setItem(
    LOCAL_STORAGE_KEY_APPS,
    JSON.stringify([app.id, ...appIds.filter((id) => id !== app.id)])
  );
};

export const AppsProvider = ({ children }: PropsWithChildren) => {
  const { member, status } = useMember();
  const navigate = useNavigate();
  const dispatch = useContext(NotificationDispatch);
  const userMemberPermissions = member ? flattenPermissions(member) : [];

  const checkHasAnyPermission = (permissions: Permission[]) =>
    includesAny(userMemberPermissions, permissions);

  const accounting: Department = "accounting";
  const payroll: Department = "payroll";
  const webMarketing: Department = "webMarketing";

  const handleError = (error: unknown) =>
    dispatch(showErrorNotification(error, "Fehler beim Öffnen der App."));

  const apps: App[] = [
    {
      id: "64f27b30-0cfa-4a71-be86-cee9f60a8ee0",
      name: "Tagesinkassobuch",
      department: accounting,
      description:
        "Dient der vorgeschriebenen Erfassung der täglichen Tagesinkassi und bei ordentlicher Buchhaltung zur Führung des Kassabuches (Bargeldbewegungen).",
      isVisible: checkHasAnyPermission([Permission.TIBuchRead]),
      onNavigate: () => openInNewTab("https://service.hgv.it/ti/"),
    },
    {
      id: "338ac8af-e647-4d26-8a5e-6fa5b345392c",
      name: "Monatsdaten",
      department: accounting,
      description:
        "Dient zur monatlichen Erfassung der betrieblichen Strukturdaten, wie Bettenanzahl, Nächtigungen, Sitzplätze, Öffnungstage.",
      isVisible: checkHasAnyPermission([
        Permission.MonatsdatenDataWrite,
        Permission.MonatsdatenSettingsWrite,
      ]),
      onNavigate: () => openInNewTab("https://monatsdaten.hgv.it/"),
    },
    {
      id: "19ce9fd3-c566-442d-90a8-6ef0a47d9159",
      name: "Kennzahlen",
      department: accounting,
      description:
        "Dient zur Einsicht steuerlicher und zivilrechtlicher Kennzahlen.",
      isVisible: checkHasAnyPermission([Permission.AccountingsRead]),
      onNavigate: () =>
        navigate("/kennzahlen/buchhalterische-drei-jahres-analyse"),
    },
    {
      id: "feb073af-de03-42a5-9b30-881f093d3020",
      name: "Business – Check",
      department: accounting,
      description:
        "Dient zur laufenden Überwachung der Betriebsentwicklung mittels aktueller Bilanzdaten, Grafiken und Jahresvergleiche.",
      isVisible: checkHasAnyPermission([Permission.BusinessCheckExecute]),
      onNavigate: () => openInNewTab("https://businesscheck.hgv.it/"),
    },
    {
      id: "31e405f6-7241-4be0-b4e3-a5059e4fe304",
      name: "Offene Posten",
      department: accounting,
      description: "Dient zur aktualisierten Übersicht aller offenen Posten.",
      isVisible: checkHasAnyPermission([Permission.OpenItemsRead]),
      onNavigate: () => navigate("/steuerberatung/offene-posten"),
    },
    {
      id: "9c154b27-848a-4ce6-a251-afba73920f0a",
      name: "Rechnungsportal",
      department: accounting,
      description:
        "Dient zur einfachen Erstellung und Verwaltung aller Eingangs- und Ausgangsrechnungen.",
      isVisible: checkHasAnyPermission([Permission.InvoicePortalExecute]),
      onNavigate: () => {
        if (!member) return;
        return getMetropolisUrl(member.id)
          .then((redirectUrl) => openInNewTab(redirectUrl))
          .catch(handleError);
      },
    },
    {
      id: "c8c6c480-443a-401a-bce2-c8f290de39c8",
      name: "Endbestände",
      department: accounting,
      description:
        "Detaillierte Erfassung der Endbestände von Waren und Güter zum Jahresende.",
      isVisible: checkHasAnyPermission([
        Permission.EndbestaendeWorksheetsWrite,
        Permission.EndbestaendeSettingsWrite,
      ]),
      publishedAt: new Date(2023, 11, 27),
      onNavigate: () => openInNewTab("https://endbestaende.hgv.it/"),
    },
    {
      id: "7380e1bb-ddb9-4cf0-aa1a-9deda4c0c850",
      name: "Arbeitssicherheit für Arbeitnehmer:innen",
      department: payroll,
      description:
        "Anmeldung zu dem gesetzlich vorgeschriebenen Arbeitssicherheitskurs für Mitarbeiter:innen.",
      isVisible: checkHasAnyPermission([
        Permission.DocumentsBetriebOnlineServiceRead,
        Permission.DocumentsBetriebStandardRead,
        Permission.DocumentsArbeitsvertraegeRead,
      ]),
      onNavigate: () => navigate("/personalberatung/arbeitssicherheit"),
    },
    {
      id: "bd8c9060-43d6-45e3-bf05-9d9665a24907",
      name: "Stundenregister",
      department: payroll,
      description:
        "Einfache Eingabe und Verwaltung der Arbeitsstunden der Mitarbeiter. Kein Ausdrucken und Ablegen der Stundenregister mehr.",
      isVisible: checkHasAnyPermission([Permission.HourRegistrationExecute]),
      onNavigate: () => {
        if (!member) return;
        return getWebdeskUrl("stundenregister", member.id)
          .then((redirectUrl) => openInNewTab(redirectUrl))
          .catch(handleError);
      },
    },
    {
      id: "7df24aeb-28ce-465e-a9c1-fba7d68b1ed9",
      name: "Mitarbeitermeldung",
      department: payroll,
      description: (
        <>
          Um die termingerechte Meldung an das Arbeitsamt sowie die
          Bereitstellung des Arbeitsvertrages garantieren zu können, werden die
          vollständigen Informationen <strong>bis spätestens 11.45 Uhr</strong>{" "}
          des letzten Werktages vor Arbeitsbeginn benötigt.
        </>
      ),
      isVisible: checkHasAnyPermission([
        Permission.EmployeeRegistrationExecute,
      ]),
      onNavigate: () => {
        if (!member) return;
        return getWebdeskUrl("mitarbeitermeldung", member.id)
          .then((redirectUrl) => openInNewTab(redirectUrl))
          .catch(handleError);
      },
    },
    {
      id: "35c662b3-0583-4b09-91f9-7133c0c4f2ae",
      name: "Dringende Mitarbeiteranmeldung",
      department: payroll,
      description:
        "Dringende Mitarbeiteranmeldung außerhalb der Öffnungszeiten.",
      isVisible: checkHasAnyPermission([
        Permission.EmployeeRegistrationExecute,
      ]),
      onNavigate: () => navigate("/personalberatung/uniurg"),
    },
    {
      id: "e27893a8-6e38-4c7d-ab70-a113931e9812",
      name: "Notfalldienst",
      department: payroll,
      description:
        "Häufige Problemfälle mit Fragen und Antworten (FAQ). Falls die angegebenen FAQs Ihren konkreten Problemfall nicht lösen, kann ein individueller Notfalldienst aktiviert werden.",
      isVisible: member?.giottoId !== undefined,
      onNavigate: () => navigate("/personalberatung/notfalldienst"),
    },
    {
      id: "3d00b340-1051-4a07-8d7d-03e165b56d4f",
      name: "Webseitenanalyse",
      department: webMarketing,
      description:
        "Dient zur Analyse des Nutzerverhaltens auf ihren Webseiten.",
      isVisible: checkHasAnyPermission([Permission.WebsiteMetricsRead]),
      onNavigate: () => navigate("/kennzahlen/webseitenanalyse"),
    },
    {
      id: "93c8acea-10d9-4ef6-8ba9-0a9bb39164b0",
      name: "DAC7 Meldepflicht",
      department: webMarketing,
      description: (
        <>
          Aufgrund des legislativen Dekrets Nr. 32/2023, welches die
          EU-Richtlinie „DAC7“ in Italien umsetzt, sind digitale
          Plattformbetreiber dazu verpflichtet, europäischen Steuerbehörden
          Daten und Transaktionen der registrierten Verkäufer offenzulegen.
          <br />
          Bitte ergänzen Sie die fehlenden Daten bis zum 28.12.2023, um eine
          Deaktivierung der online Buchbarkeit zu vermeiden.
        </>
      ),
      publishedAt: new Date(2023, 12, 7),
      isVisible: checkHasAnyPermission([Permission.Dac7Write]),
      onNavigate: () => navigate("/web-marketing/dac7"),
    },
  ].map((app) => ({
    ...app,
    onNavigate: async () => {
      const result = app.onNavigate();
      if (result instanceof Promise) {
        await result;
      }
      addRecentlyUsedApp(app);
    },
  }));

  const getSortedRecentlyUsedApps = () => {
    const recentlyUsedAppIds = getRecentlyUsedAppIds();
    return (
      apps
        .filter(
          ({ id, isVisible }) => recentlyUsedAppIds.includes(id) && isVisible
        )
        .sort(
          (a, b) =>
            recentlyUsedAppIds.indexOf(a.id) - recentlyUsedAppIds.indexOf(b.id)
        ) ?? []
    );
  };

  const value = {
    apps,
    recentlyUsedApps: getSortedRecentlyUsedApps().slice(0, 5),
    isValidating: status === "validating",
  };

  return <AppsContext.Provider value={value}>{children}</AppsContext.Provider>;
};

export const useApps = () => {
  return useContext(AppsContext);
};
