import type { Location } from "@remix-run/router";
import download from "downloadjs";
import { SWRResponse } from "swr";
import { WretchResponse } from "wretch";
import { SP } from "./http/serviceportalApi";
import { Indexed, Office, Permission, RemoteDataStatus } from "./types";

export const notAvailableMsg = "N / V";

export const configIncompleteMsg =
  "Die Konfiguration des Betriebes wurde noch nicht vollständig abgeschlossen.";

export const range = (start: number, stop: number, step = 1): number[] => {
  const range: number[] = [];
  for (let i = start; i <= stop; i = i + step) {
    range.push(i);
  }
  return range;
};

export const getRemoteDataStatus = ({
  isValidating,
  error,
}: Partial<SWRResponse>): RemoteDataStatus => {
  if (isValidating) return "validating";
  if (error) return "failure";
  return "success";
};

export const mergeStatuses = (
  statuses: RemoteDataStatus[]
): RemoteDataStatus => {
  if (statuses.includes("failure")) return "failure";
  if (statuses.includes("validating")) return "validating";
  return "success";
};

const defaultLocale = "de-DE";

export const formatAsPercent = (fractionDigits: number, n: number): string => {
  return n.toLocaleString(defaultLocale, {
    style: "percent",
    minimumFractionDigits: fractionDigits,
  });
};

export const formatAsNumberWith = (fractionDigits: number, n: number): string =>
  n.toLocaleString(defaultLocale, {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  });

export const formatAsCurrencyWith = (
  fractionDigits: number,
  n: number
): string => {
  return n.toLocaleString(defaultLocale, {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  });
};

export const formatAsTime = (timeInSeconds: number) => {
  const x = new Date(Math.round(timeInSeconds) * 1000);
  const hours = x.getUTCHours();
  const minutes = x.getUTCMinutes();
  const seconds = x.getUTCSeconds();
  const empty = "";
  if (hours > 0 || minutes > 0 || seconds >= 0) {
    const h = hours > 0 ? `${hours}h` : empty;
    const m = minutes > 0 ? `${minutes}m` : empty;
    const s = seconds >= 0 ? `${seconds}s` : empty;
    return `${h} ${m} ${s}`.trim();
  } else {
    return notAvailableMsg;
  }
};

const formatLongMonth = (date: Date) => {
  return date.toLocaleString(defaultLocale, {
    month: "long",
  });
};

export const getLongMonthName = (month: number) => {
  const date = new Date();
  date.setDate(1);
  date.setMonth(month - 1);
  return formatLongMonth(date);
};

export const formatShortDayMonth = (date: string) =>
  new Date(date).toLocaleString(defaultLocale, {
    day: "numeric",
    month: "short",
  });

export const formatShortYearMonth = (date: string) =>
  new Date(date).toLocaleString(defaultLocale, {
    month: "2-digit",
    year: "2-digit",
  });

export const formatShortCalendarWeek = (date: string) =>
  `KW ${getIsoWeek(date)}`;

export const formatLongDate = (date: string) => {
  return new Date(date).toLocaleString(defaultLocale, {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
};

export const formatLongDateTime = (date: string) => {
  return new Date(date).toLocaleString(defaultLocale, {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  });
};

export const getStartOfIsoWeek = (date: string) => {
  const d = new Date(date);
  return new Date(d.setDate(d.getDate() - d.getDay() + 1)).toISOString();
};

export const getEndOfIsoWeek = (date: string) => {
  const d = new Date(date);
  return new Date(d.setDate(d.getDate() + 7 - d.getDay())).toISOString();
};

const getIsoWeek = (date: string) => {
  const millisecondsInWeek = 604800000;
  const startOfIsoWeek = new Date(getStartOfIsoWeek(date));
  const fourthOfJanuary = new Date(
    startOfIsoWeek.getFullYear(),
    0,
    4
  ).toISOString();
  const diff =
    startOfIsoWeek.getTime() -
    new Date(getStartOfIsoWeek(fourthOfJanuary)).getTime();
  return Math.round(diff / millisecondsInWeek) + 1;
};

export const formatYearWeek = (date: string) => {
  const d = new Date(date);
  return `${d.getFullYear()} (${getIsoWeek(date)})`;
};

export const formatYear = (date: string) => {
  return new Date(date).toLocaleString(defaultLocale, {
    year: "numeric",
  });
};

export const formatYearMonthD3 = (
  date: string,
  include13: boolean,
  include14: boolean
) => {
  const d = new Date(date);
  const year = d.getFullYear();
  const month =
    d.getMonth() + 1 + Number(include13) * 1 + Number(include14) * 2;
  return `${year}-${month.toString().padStart(2, "0")}`;
};

export const getLastOfMonth = (date: Date) => {
  date.setMonth(date.getMonth() + 1);
  date.setDate(0);
  return date;
};

const colors = [
  "var(--charts-1)",
  "var(--charts-2)",
  "var(--charts-3)",
  "var(--charts-4)",
  "var(--charts-5)",
  "var(--charts-6)",
  "var(--charts-7)",
];

export const colorCycle = (i: number) => {
  return colors[i % colors.length];
};

export const formatYearMonth = ({ year, month }: SP.YearMonth) =>
  `${year}${month.toString().padStart(2, "0")}`;

export const getDefaultPeriod = (
  until = new Date(),
  subtract = 0
): SP.Period => {
  const year = until.getFullYear();
  const month = until.getMonth() + 1;
  return {
    from: { year, month: 1 },
    until: { year, month: month + subtract },
  };
};

export const getFullYearPeriod = (date: Date): SP.Period => {
  const year = date.getFullYear();
  return {
    from: { year, month: 1 },
    until: { year, month: 12 },
  };
};

export const getWageCostPeriodUntil = (): Date => {
  const d = new Date();
  const month = d.getMonth();
  // Wage costs for the current month are usually available from the 25th
  const monthsToSubtract = d.getDate() >= 25 ? 0 : 1;
  d.setMonth(month - monthsToSubtract);
  return d;
};

export const getAccountingPeriodUntil = (): Date => {
  const d = new Date();
  const month = d.getMonth();
  const monthsToSubtract = d.getDate() >= 16 ? 1 : 2;
  d.setMonth(month - monthsToSubtract);
  return d;
};

export const indexed = <T>(xs: T[]): Indexed<T>[] =>
  xs.map((x, index) => [x, index]);

export function* takeWhile<T>(fn: (x: T) => boolean, xs: T[]) {
  for (let x of xs)
    if (fn(x)) yield x;
    else break;
}

export const union = <T>(a: Set<T>, b: Set<T>): Set<T> => new Set([...a, ...b]);

export const difference = <T>(a: Set<T>, b: Set<T>): Set<T> =>
  new Set([...a].filter((x) => !b.has(x)));

export const isSubset = <T>(a: Set<T>, b: Set<T>): boolean =>
  [...a].every((x) => b.has(x));

export const removeDuplicates = <T>(array: T[]): T[] => [...new Set(array)];

export const includesAny = <T>(a: T[], b: T[]): boolean =>
  b.some((elem) => a.includes(elem));

export const downloadFile = async (res: WretchResponse) => {
  const blob = await res.blob();

  const contentDisposition = res.headers.get("content-disposition");
  var filename: string | undefined;
  if (contentDisposition !== null) {
    const match = new RegExp('filename="(.*)"', "g").exec(contentDisposition);
    filename = match?.[1];
  }

  let fallbackMimeType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
  const mimeType = res.headers.get("content-type") ?? fallbackMimeType;

  return download(new Blob([blob]), filename, mimeType);
};

export const getYearFromPeriod = (
  subtract: number,
  { from: { year: fromYear }, until: { year: untilYear } }: SP.Period
) =>
  fromYear === untilYear
    ? String(untilYear + subtract)
    : `${fromYear + subtract}/${untilYear + subtract}`;

export const getValueBasedOnValueType = (
  [value, percent]: SP.ValuePercent,
  valueType: SP.ValueType
) => (valueType === "currency" ? value : percent);

export const getPayrollOffice = (giottoId?: number): Office | undefined => {
  if (!giottoId) return;

  switch (Number(String(giottoId).charAt(0))) {
    case 1:
      return "bozen";
    case 2:
      return "meran";
    case 3:
      return "brixen";
    case 4:
      return "bruneck";
    case 5:
      return "schlanders";
    // Privathaushalte start with 9, the responsible office is represented by
    // the second digit
    case 9:
      return getPayrollOffice(Number(String(giottoId).slice(1)));
    default:
      return;
  }
};

export const sumOpenItems = (openItems: SP.OpenItem[]) => {
  const result =
    Math.round(
      openItems.reduce((state, { balance }) => state + balance, 0) * 100
    ) / 100;
  return result === -0 ? Math.abs(result) : result;
};

export const flattenPermissions = (member: SP.Member) => [
  ...(member.permissions ?? []),
  ...(member.companies?.flatMap((c) => c.permissions ?? []) ?? []),
];

export const checkHasAdminPermission = (permissions: string[]) =>
  includesAny(permissions, [
    Permission.UsersRead,
    Permission.UsersWrite,
    Permission.InvitesRead,
  ]);

export const checkHasEconomicAdvisorPermission = (permissions: string[]) =>
  includesAny(permissions, [Permission.DocumentsWirtschaftsberaterRead]);

export const isRouteEconomicAdvisors = (location: Location) =>
  location.pathname.startsWith("/wirtschaftsberater");

export const checkHasPrivateHouseholdPermission = (permissions: string[]) =>
  includesAny(permissions, [Permission.DocumentsPrivateHaushalteRead]);

export const isRoutePrivateHousehold = (location: Location) =>
  location.pathname.startsWith("/privathaushalt");

export const checkHasRevisorPermission = (permissions: string[]) =>
  includesAny(permissions, [Permission.DocumentsRevisorenRead]);

export const isRouteRevisor = (location: Location) =>
  location.pathname.startsWith("/revisoren");
