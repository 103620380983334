import { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { useAuth } from "../context/authContext";
import { useMember } from "../context/memberContext";
import { useMediaQuery } from "../hooks/useMediaQuery";
import useToggle from "../hooks/useToggle";
import i18n from "../i18n";
import { Permission } from "../types";
import { flattenPermissions, includesAny } from "../utils";
import styles from "./CustomerApp.module.css";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Accounting from "./accounting/Accounting";
import AgreementDetail from "./agreements/AgreementDetail";
import Agreements from "./agreements/Agreements";
import BilanzOnline from "./bilanzOnline/BilanzOnline";
import Documents from "./documents/Documents";
import Home from "./home/Home";
import {
  SvgBulletAccounting,
  SvgBulletPayroll,
  SvgBulletWebMarketing,
} from "./icons/Bullet";
import SvgDescription from "./icons/Description";
import SvgDomain from "./icons/Domain";
import SvgEqualizer from "./icons/Equalizer";
import SvgHistoryEdu from "./icons/HistoryEdu";
import SvgHome from "./icons/Home";
import SvgSupervisorAccount from "./icons/SupervisorAccount";
import Invites from "./invites/Invites";
import MasterData from "./masterData/MasterData";
import {
  accountingDocumentPermissions,
  payrollDocumentPermissions,
} from "./member/DocumentPermissions";
import MemberSelect from "./member/MemberSelect";
import MemberSettings from "./member/MemberSettings";
import MemberUser from "./member/MemberUser";
import OpenItems from "./openItems/OpenItems";
import Arbeitssicherheit from "./payroll/Arbeitssicherheit";
import EmergencyService from "./payroll/EmergencyService";
import Payroll from "./payroll/Payroll";
import UniUrg from "./payroll/UniUrg";
import Nav from "./ui/Nav";
import Dac7 from "./webMarketing/Dac7";
import WebMarketing from "./webMarketing/WebMarketing";

const CustomerApp = () => {
  const auth = useAuth();
  const matchesMedia = useMediaQuery("(min-width: 1400px)");
  const sidebar = useToggle();
  const { member, status } = useMember();
  const userMemberPermissions = member ? flattenPermissions(member) : [];

  const hasMasterData = member !== undefined;
  const hasMemberUsers = includesAny(userMemberPermissions, [
    Permission.MemberUsersRead,
    Permission.MemberUsersWrite,
  ]);
  const hasMemberUsersWrite = userMemberPermissions.includes(
    Permission.MemberUsersWrite
  );
  const hasDocuments = includesAny(userMemberPermissions, [
    ...accountingDocumentPermissions,
    ...payrollDocumentPermissions,
  ]);
  const hasBilanzOnline = includesAny(userMemberPermissions, [
    Permission.AccountingsRead,
    Permission.WageCostsRead,
    Permission.WebsiteMetricsRead,
  ]);
  const hasAgreements = userMemberPermissions.includes(
    Permission.AgreementsExecute
  );
  const hasOpenItems = userMemberPermissions.includes(Permission.OpenItemsRead);
  const hasUniUrg = userMemberPermissions.includes(
    Permission.EmployeeRegistrationExecute
  );
  const hasArbeitssicherheit = includesAny(userMemberPermissions, [
    Permission.DocumentsBetriebOnlineServiceRead,
    Permission.DocumentsBetriebStandardRead,
    Permission.DocumentsArbeitsvertraegeRead,
  ]);
  const hasEmergencyService = member?.giottoId !== undefined;
  const hasDac7 = userMemberPermissions.includes(Permission.Dac7Write);

  const closeMenu = () => {
    !matchesMedia && sidebar.close();
  };

  useEffect(closeMenu, [matchesMedia]);

  if (!auth.session) {
    return null;
  }

  return (
    <>
      <Header
        hamburger={{
          isOpen: sidebar.isOpen,
          onToggle: sidebar.toggle,
        }}
      />
      <div>
        <Sidebar isOpen={sidebar.isOpen} close={closeMenu}>
          <div className={styles.memberSelect}>
            <MemberSelect theme="dark" />
            <hr className={styles.separator} />
          </div>
          {status === "success" && (
            <Nav>
              <Nav.Item
                text="Startseite"
                to="/"
                icon={SvgHome}
                onClick={closeMenu}
              />
              {(hasMasterData ||
                hasMemberUsers ||
                hasDocuments ||
                hasBilanzOnline ||
                hasAgreements) && (
                <Nav.Group title="Mein Betrieb">
                  {hasMasterData && (
                    <Nav.Item
                      text="Stammdaten"
                      to="/stammdaten"
                      icon={SvgDomain}
                      onClick={closeMenu}
                    />
                  )}
                  {hasMemberUsers && (
                    <Nav.Item
                      text="Benutzer"
                      to="/einstellungen"
                      icon={SvgSupervisorAccount}
                      onClick={closeMenu}
                    />
                  )}
                  {hasDocuments && (
                    <Nav.Item
                      text="Dokumente"
                      to="/dokumente"
                      icon={SvgDescription}
                      onClick={closeMenu}
                    />
                  )}
                  {hasBilanzOnline && (
                    <Nav.Item
                      text="Kennzahlen"
                      to="/kennzahlen"
                      icon={SvgEqualizer}
                      onClick={closeMenu}
                    />
                  )}
                  {hasAgreements && (
                    <Nav.Item
                      text="Verträge & Preise"
                      to="/vertraege-und-preise"
                      icon={SvgHistoryEdu}
                      onClick={closeMenu}
                    />
                  )}
                </Nav.Group>
              )}
              <Nav.Group title="Dienstleistungen">
                <Nav.Item
                  text={i18n.departments.accounting.title}
                  to={`/${i18n.departments.accounting.slug}`}
                  icon={SvgBulletAccounting}
                  onClick={closeMenu}
                />
                <Nav.Item
                  text={i18n.departments.payroll.title}
                  to={`/${i18n.departments.payroll.slug}`}
                  icon={SvgBulletPayroll}
                  onClick={closeMenu}
                />
                <Nav.Item
                  text={i18n.departments.webMarketing.title}
                  to={`/${i18n.departments.webMarketing.slug}`}
                  icon={SvgBulletWebMarketing}
                  onClick={closeMenu}
                />
              </Nav.Group>
            </Nav>
          )}
        </Sidebar>
        <Routes>
          <Route index element={<Home />} />
          <Route path="einladungen" element={<Invites />} />
          {hasMasterData && (
            <Route path="stammdaten" element={<MasterData />} />
          )}
          {hasMemberUsers && (
            <Route path="einstellungen/*">
              <Route path="*" element={<MemberSettings />} />
              {hasMemberUsersWrite && (
                <>
                  <Route
                    path="benutzer/:userId/:companyId"
                    element={<MemberUser />}
                  />
                  <Route path="benutzer/:userId" element={<MemberUser />} />
                </>
              )}
            </Route>
          )}
          {hasDocuments && <Route path="dokumente/*" element={<Documents />} />}
          {hasBilanzOnline && (
            <Route path="kennzahlen/*" element={<BilanzOnline />} />
          )}
          {hasAgreements && (
            <Route path="vertraege-und-preise/*">
              <Route index element={<Agreements />} />
              <Route
                path=":id/:version/:showVexatoryClauses/*"
                element={<AgreementDetail />}
              />
              <Route path=":id/:version/*" element={<AgreementDetail />} />
            </Route>
          )}
          <Route path={i18n.departments.accounting.slug}>
            <Route index element={<Accounting />} />
            {hasOpenItems && (
              <Route path="offene-posten" element={<OpenItems />} />
            )}
          </Route>
          <Route path={i18n.departments.payroll.slug}>
            <Route index element={<Payroll />} />
            {hasUniUrg && <Route path="uniurg" element={<UniUrg />} />}
            {hasArbeitssicherheit && (
              <Route path="arbeitssicherheit" element={<Arbeitssicherheit />} />
            )}
            {hasEmergencyService && (
              <Route path="notfalldienst" element={<EmergencyService />} />
            )}
          </Route>
          <Route path={i18n.departments.webMarketing.slug}>
            <Route index element={<WebMarketing />} />
            {hasDac7 && <Route path="dac7" element={<Dac7 />} />}
          </Route>
        </Routes>
      </div>
    </>
  );
};

export default CustomerApp;
